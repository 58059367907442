<template>
  <div class="container">
    <BaseHeader title="Gestão de funis" :navigation="navigation"> </BaseHeader>

    <div class="container-table">
      <div class="box-total" v-show="!loading">
        <div class="total-vendas info"></div>
        <div>
          <p class="total-vendas">
            Total de funis:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div v-show="loading">
        <b-skeleton
          style="border-radius: 10px"
          width="100%"
          height="83px"
          class="mt-1"
        ></b-skeleton>
      </div>

      <div class="header-table">
        <b-col
          cols="12"
          md="4"
          class="container-pesquisa"
          :class="{ pd: isMobile }"
        >
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Pesquisar funil"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
        <div>
          <BaseButton
            @click="
              $router.push({
                name: 'CampanhasCriar',
              })
            "
            variant="primary"
            v-if="!isMobile"
          >
            Adicionar funil
          </BaseButton>
        </div>
      </div>

      <!-- BODY -->
      <template v-if="!loading && campaings.length > 0">
        <div class="paginas-conteudo-container">
          <div v-if="cloning">
            <b-skeleton height="217px" width="370px"></b-skeleton>
            <div style="display: flex; gap: 140px">
              <b-skeleton width="130px" class="mt-4"></b-skeleton>
              <b-skeleton width="60px" class="mt-4"></b-skeleton>
            </div>
            <b-skeleton width="60px" class="mt-4"></b-skeleton>
          </div>
          <div
            class="paginas-conteudo"
            v-for="item in campaings"
            :key="item.id"
          >
            <div @click="$router.push(`lista/${item.id}`)">
              <div
                v-if="
                  item.metas &&
                  item.metas[0] &&
                  item.metas[0].meta_value &&
                  item.metas[0].meta_value !== undefined 
                "
              >
                <img
                  :src="getCampaignImage(item.metas)"
                  class="img-conteudo"
                  alt="Capa do funil"
                />
              </div>
              <div v-else>
                <img
                  :src="
                    getFirstCoverPage(
                      item.pages &&
                        item.pages.length &&
                        item.pages[item.pages.length - 1].metas
                        ? item.pages[item.pages.length - 1].metas
                        : []
                    )
                  "
                  class="img-conteudo"
                  alt="imagem-vazia"
                />
              </div>
            </div>
            <div class="paginas-descricao">
              <div class="display-flex2">
                <h1 class="paginas-descricao-titulo">{{ item.title }}</h1>
                <div id="cardFunil" v-if="!isMobile">
                  <b-dropdown id="dropPage" right style="margin-top: 12px">
                    <template #button-content>
                      <img src="@/assets/img/icons/pontos.svg" alt="dots" />
                    </template>
                    <b-dropdown-item @click="openModalEdit(item)"
                      >Editar</b-dropdown-item
                    >
                    <b-dropdown-item @click="cloneCampaign(item)"
                      >Clonar</b-dropdown-item
                    >
                    <b-dropdown-item @click="deleteCampaing(item.id)"
                      >Remover</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>

              <!-- <div v-if="getDiferenceDays(item.post_date)" class="display-flex">
                <img
                  class="img-icon"
                  src="@/assets/icons/calendar.svg"
                  alt="icon"
                />
                <div>
                  {{ item.post_date | datetime }} |
                  {{ getDiferenceDays(item.post_date) }}
                  {{
                    getDiferenceDays(item.post_date) === 1
                      ? "dia ativo"
                      : "dias ativos"
                  }}
                </div>
              </div> -->

              <div class="display-flex">
                <img
                  class="img-icon ml-2"
                  src="@/assets/img/icons/hash.svg"
                  alt="icon"
                />{{ item.id }}
              </div>
              <!-- <div v-if="item.senha_premium.length" class="display-flex">
                <img
                  class="img-icon"
                  src="@/assets/img/icons/key.svg"
                  alt="icon"
                />
                <span>Código Premium: {{ item.senha_premium }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </template>
      <div v-if="loading" class="indicador d-flex grid gap-3 row">
        <div
          class="p-2"
          v-for="(item, index) in pagination.perPage"
          :key="index"
        >
          <b-skeleton height="217px" width="370px"></b-skeleton>
          <div style="display: flex; gap: 140px">
            <b-skeleton width="130px" class="mt-4"></b-skeleton>
            <b-skeleton width="60px" class="mt-4"></b-skeleton>
          </div>
          <b-skeleton width="60px" class="mt-4"></b-skeleton>
        </div>
      </div>
      <b-row>
        <b-col>
          <Paginate
            v-if="campaings.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :disabled="true"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!loading && !campaings.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum funil foi encontrado</p>
      </b-row>

      <!-- END BODY -->
      <htmlModal :form="selectedHtml" />

      <ModalEdit
        @updateTitle="updateTitle"
        @updateCampaign="updateCampaign"
        :titleRequired="titleRequired"
        :titleLength="titleLength"
        :titleSpace="titleSpace"
      />
    </div>
  </div>
</template>
<script>
import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import htmlModal from "@/components/Formularios/htmlModal";
import Upload from "../Editor/Upload.vue";
import ModalEditTitleCampanha from "./ModalEditTitleCampanha.vue";
import ModalEdit from "./ModalEdit.vue";
import Vue from "vue";
import moment from "moment-timezone";
import _ from "lodash";
import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();
import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";

export default {
  name: "ListagemVendas",
  components: {
    BaseButton,
    Paginate,
    BaseHeader,
    htmlModal,
    Upload,
    ModalEdit,
  },
  data() {
    return {
      navigation: [{ link: "Funis", to: this.$route.fullPath }],
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      newTitle: "",
      coverCampanhaEdit: "",
      dataEdit: [],
      campaings: [],
      client: {
        width: 0,
      },
      selectedHtml: "",
      total: 0,
      search: null,
      loading: true,
      leads: [],
      filters: {},
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      cloning: false
    };
  },
  computed: {
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 30 : 37;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },

  methods: {
    validSpace() {
      const regex = /  /;
      this.titleSpace = regex.test(this.newTitle);
    },
    getFirstCoverPage(metas) {
      let photo = metas.find((x) => x.meta_key == "page_cover");
      if (photo) {
        return photo.meta_value == ""
          ? require("@/assets/img/icons/img_null.svg")
          : photo.meta_value;
      }
      return require("@/assets/img/icons/img_null.svg");
    },
    getCampaignImage(metas) {
      var result = metas.find((x) => x.meta_key == "metas");
      if (result) {
        return result.meta_value.length
          ? result.meta_value
          : require("@/assets/img/icons/img_null.svg");
      }

      return require("@/assets/img/icons/img_null.svg");
    },

    openModalEdit(data) {
      this.dataEdit = data;

      this.$bvModal.show("Modal-Edit", data.id);
    },

    updateTitle(title) {
      this.newTitle = title;
      let dataEdit = {
        title: this.newTitle,
        status: this.dataEdit.status,
        id: this.dataEdit.id,
      };

      serviceCampaign
        .update(dataEdit)
        .then(() => {
          this.$bvModal.hide("Modal-Edit");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateCampaign(img) {
      this.updateTitle();
      this.coverCampanhaEdit = img;

      let dataEdit = {
        status: this.dataEdit.status,
        metas: this.coverCampanhaEdit,
        id: this.dataEdit.id,
      };

      serviceCampaign
        .update(dataEdit)
        .then(() => {
          this.$bvModal.hide("Modal-Edit");

          this.$bvToast.toast(`Campanha editada com sucesso.`, {
            title: "Funil",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$bvToast.toast("Erro ao editar campanha.", {
            title: "Funil",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.fetchCampanhas();
        });
    },
    deleteCampaing(id) {
      Vue.swal({
        title: "Funil",
        text: `Deseja deletar o funil escolhido?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceCampaign
            .destroy(id)
            .then((resp) => {
              this.$bvToast.toast("Funil removido", {
                title: "Gestão de funis",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              // this.$store.dispatch("formRequest");
            })
            .catch((error) => {
              this.$bvToast.toast("Erro ao remover funil", {
                title: "Gestão de funis",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.fetchCampanhas();
            });
        }
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchCampanhas(this.search, page);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchCampanhas(this.search);
    },
    async fetchCampanhas(search = null, page) {
      // const cachedData = await getCachedData("fetchCampanhas");
      this.loading = true;

      // if (cachedData && !page) {
      //   this.campaings = cachedData.data;
      //   this.total = cachedData.total;
      //   this.pagination.totalPages = cachedData.last_page;
      //   this.pagination.currentPage = cachedData.current_page;
      //   this.pagination.perPage = cachedData.per_page;
      //   this.loading = false;
      // }

      this.pagination.totalPages = 1;

      let data = {
        page: this.pagination.currentPage,
      };

      if (this.search) {
        data.search = this.search;
      }

      serviceCampaign
        .search(data)
        .then((response) => {
          // cacheData("fetchCampanhas", response);
          this.campaings = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchCampanhas(this.search);
    }, 500),
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchCampanhas();
    },
    openModal(data, form) {
      this.$bvModal.show(data);
      this.selectedHtml = form;
    },
    cloneCampaign(campaign){
      Vue.swal({
        title: "Funil",
        text: `Deseja clonar o funil ${campaign.title}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Clonar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cloning = true;
          serviceCampaign
            .createId({id: `clone/${campaign.id}`})
            .then((resp) => {
              this.campaings.unshift(resp["0"]);
              this.total += 1;
              this.$bvToast.toast("Funil clonado com sucesso!", {
                title: "Gestão de funis",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((error) => {
              this.$bvToast.toast("Erro ao clonar funil!", {
                title: "Gestão de funis",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.cloning = false;
            });
        }
      });
    }
  },
  watch: {
    newTitle: "validSpace",
  },
  created() {
    let level = this.$store.getters.user.user.level;
    if(level == 'templater' || level == 'seller' || level == 'gerent_seller'){
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    setupDB()
      .then(() => {
        this.fetchCampanhas();
      })
      .catch((error) => {
        this.fetchCampanhas();
      });
  },
};
</script>

<style lang="scss" scoped>
.paginas-menu {
  border-radius: 10px;
  border: none;
}

.margin {
  height: 60px !important;
}

input {
  margin-bottom: 0 !important;
}

.paginas-descricao-opt {
  color: var(--greenn);
}

.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
  width: 220px;
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  user-select: none;

  &::-webkit-scrollbar {
    width: 20px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.123) !important;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.123) !important;
  }
}

.paginas-descricao {
  padding: 30px 30px;
}

.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
  cursor: pointer;
}

.img-conteudo {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}

.img-icon {
  width: 12px;
  height: 13.5px;
}

.display-flex2 {
  display: grid;
  grid-template-columns: 1fr 80px;
}

.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

//
.pd {
  padding-right: 0;
  padding-left: 0;
}

.id {
  padding-left: 22px;
}

@media (max-width: 1024px) {
  .paginas-conteudo-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .input-busca {
    position: relative !important;
  }

  .paginas-conteudo-container {
    grid-template-columns: 1fr;
  }

  .col-1 {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .col-2 {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .col-3 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.break {
  word-break: break-all;
}

.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}

.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}

.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}

.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  padding-top: 15px;
}

.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}

.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}

.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  top: 0px;
  left: 0;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: 17px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 23px;
  }
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }

  .Table-body {
    gap: 50px;
  }

  .header-table {
    display: block;
    padding: 0;
    position: relative;
  }

  .flex-sale {
    display: block;
  }

  .flex-sale div {
    margin-left: 0;
  }

  .flex-acoes {
    margin: 15px 0;
  }

  .container-pesquisa {
    text-align: left;
    padding-bottom: 10px;
  }

  .search {
    left: 20px;
  }

  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}

.dados-cliente div {
  margin-bottom: 3px;
}

.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}

.whats-cliente svg {
  margin-right: 10px;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.Table-body {
  padding: 15px;
}

.Table-body::before {
  left: 0;
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}

.title-report-margin-top {
  margin-top: 41px;
}

.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}
</style>
